// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const self = this

    this.owl = $(this.element).owlCarousel({
      margin: 0,
      loop: false,
      autoplay: false,
      nav: true,
      dots: false,
      autoWidth: true,
      startPosition: this.data.get('startPosition'),
      // onInitialized: function() {
      //   setTimeout(function() {
      //     self.owl.trigger('refresh.owl.carousel')
      //   }, 200);
      // }
    })
  }

  disconnect() {
    this.owl.owlCarousel('destroy')
  }
}

import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player';
import YouTubePlayer from 'youtube-player';

export default class extends Controller {
  static targets = [ "poster", "player" ]

  initialize() {
    // console.log('video-player-controller initializer')
    this.cleanUpFunctions = []
  }

  connect() {
    // console.log('video-player-controller connected')
    if(this.data.get('autoplay') == 'true') {
      this.playVideo()
    }
  }

  playVideo() {
    // console.log('play video')
    this.element.classList.toggle('active', true)
    if(this.playerTarget.hasAttribute('data-youtube-id')) {
      this.playYoutube()
    } else if(this.playerTarget.hasAttribute('data-vimeo-id')) {
      this.playVimeo()
    }
  }

  playYoutube() {
    // console.log('playYoutube')
    const self = this
    const player = YouTubePlayer(this.playerTarget, {
      videoId: this.playerTarget.getAttribute('data-youtube-id'),
      playerVars: {
        autoplay: true,
        color: 'white',
        rel: 0,
      }
    });
    player.on('stateChange', (event) => {
      // console.log('Youtube state changed:', event)
      if(event.data == YT.PlayerState.ENDED) {
        self.videoEnded()
      }
    })
    this.cleanUpFunctions.push(function() {
      player.destroy()
    })
  }

  playVimeo() {
    // console.log('playVimeo')
    const player = new Player(this.playerTarget);
    this.cleanUpFunctions.push(function() {
      player.destroy()
    })
    const self = this
    player.on('ended', function() {
      self.videoEnded()
    });
  }

  videoEnded() {
    // console.log('videoEnded')
    this.reset()
  }

  reset() {
    this.element.classList.toggle('active', false)
    while(this.cleanUpFunctions.length) {
      const cleanUpFunction = this.cleanUpFunctions.pop()
      // console.log('calling clean up function…')
      cleanUpFunction()
    }
    // console.log('player has been reset')
  }

  disconnect() {
    // console.log('video-player-controller disconnected')
    this.reset()
  }
}

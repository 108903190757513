import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "text" ]

  mouseover() {
    // console.log("mouseover")
    this.textTarget.classList.toggle("d-none", false)
  }

  mouseout() {
    // console.log("mouseout")
    this.textTarget.classList.toggle("d-none", true)
  }
}
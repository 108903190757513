// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "./src/jquery"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

import 'bootstrap'

// document.addEventListener('turbolinks:load', () -> {
//   $('[data-toggle="tooltip"]').tooltip()
//   $('[data-toggle="popover"]').popover()
// })

import 'owl.carousel'

// document.addEventListener('turbolinks:load', function() {
//   if($(window).height() >= 768) {
//     var paddingBottom = $('footer').height() + 48;
//   } else {
//     var paddingBottom = 0;
//   }
//   $('body').css('paddingBottom', paddingBottom);
// })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import "./controllers"

// $(document).on('turbolinks:load', function() {
//   if(window['spFormBootstrap']) {
//     window.spFormBootstrap()
//   }
// })

// $(document).on('turbolinks:load', function() {
//   $('.dates--screening').addClass('animated fadeIn')
// })
// document.addEventListener("turbolinks:before-cache", function() {
//   $('.dates--screening').removeClass('animated fadeIn');
// })

import "./controllers"
